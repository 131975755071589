import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import moment from 'moment'


const PostCard = (props) => (
    <div class={props.size+" column home_post_item"}>
    <Link to={'/blog/'+props.path} title={props.title}>


      <div style={props.banner}>
       &nbsp;
      </div>

      <div className="homepost_date">
      {moment(props.date).format('DD MMMM YYYY')}
      </div>

      <div>
        <h5 className="homepost_title">{props.title}</h5>
        <p className="homepost_description">
        {props.description}
        </p>
        <div className="homepost_readtime">
        {props.duration} read
        </div>
      </div>

    </Link>
    </div>
)


export default PostCard
