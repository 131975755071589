import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"

import SignupForm from "../components/signupform"
import Posts from "../components/posts"

import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"

import PostCard from '../components/postcard'
import moment from 'moment'

import Logo from "../images/fb-banner-01.jpg"

const SecondPage = () => {

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
    {
      allMarkdownRemark(
        sort: {
          fields: [frontmatter___date]
          order: DESC
        }
      ) {
        totalCount
        edges {
          node {
            frontmatter {
              date
              path
              title
              author
              description
              duration
              image
            }
          }
        }
      }
    }
    `
  )

  const chunk = (array, size) => {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index));
      index += size;
    }
    return chunked_arr;
  }

  const post_groups = chunk(allMarkdownRemark.edges,3);


  return (

  <Layout>
    <SEO title="Latest Posts" image={Logo}/>
    <Header/>



    <div class="section">
      <div class="container">

      <div class="row post_row">
        <div class="column">
        <h1>Blog and latest posts</h1>
        </div>
      </div>

      {post_groups.map(function(group,group_index){

        return(
        <div class="row post_row">
          {group.map(function(item,i){

           return(
               <PostCard
                 size='one-third'
                 banner={{
                   color: 'white',
                   backgroundImage: 'url(/thumbs/' + item.node.frontmatter.image + ')',
                   padding: '0rem 0rem 0rem',
                   backgroundSize: 'cover',
                   backgroundRepeat: 'no-repeat',
                   backgroundPosition: 'center center',
                   minHeight: '20rem'
                 }}
                 path={item.node.frontmatter.path}
                 title={item.node.frontmatter.title}
                 description={item.node.frontmatter.description}
                 duration={item.node.frontmatter.duration}
                 date={item.node.frontmatter.date}
                 />
                )
            })
          }
          </div>
        )})}

      </div>
    </div>

    <div class="section signup_blog">
      <div class="container">

      <div class="row">
        <div class="column">
        <h5>Sign up, and be notified of our latest content</h5>
        </div>
      </div>

      <div class="row">
        <div class="one-half column">

          <SignupForm/>

          <Link to="/">Back to the Homepage</Link>

        </div>
        <div class="one-half column">
        </div>
      </div>
      </div>
    </div>

  </Layout>

  )
}

export default SecondPage
